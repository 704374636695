import React from "react";
import { graphql } from "gatsby";
import UseCaseTemplate from "../../../templates/use-case-template";

const ServicePage = ({ data: { useCase } }) => (
  <UseCaseTemplate useCase={useCase} />
);

export default ServicePage;

export const query = graphql`
  query ($id: String!, $language: String!) {
    useCase: useCaseJson(id: { eq: $id }) {
      enSlug: slug
      experts
      meta {
        title {
          en
        }
        description {
          en
        }
        datePublished
        dateModified
      }
      challenge {
        challenges {
          en
        }
        description {
          en
        }
      }
      client {
        client
        image
        clientDescription {
          en
        }
      }
      hero {
        projectName {
          en
        }
        services
        subtitle {
          en
        }
      }
      id
      result {
        results {
          en
        }
      }
      solution {
        solution {
          en
        }
      }
      techs
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
